// imports react and next
import Router, { useRouter } from "next/router";
import { useDispatch } from "react-redux"; //
import { useState, useEffect } from "react";

// imports styles
import styles from "./LanguageSelector.module.css";

// imports components
import GlobeIcon from "@/components/Icons/GlobeIcon";
import CloseIcon from "@/components/Icons/CloseIcon";

// imports constants
import { LocalesMapping } from "@/constants/localesMapping";

// imports redux
import { setSelectedLang } from "@/redux/actions/labelsAndConfigActions"; //

// imports utilities
import { set_cookie } from "@/utils/functions";

const LangSelector = (props) => {
  const { locales, isHorizontal = false } = props;

  const dispatch = useDispatch();
  const { asPath, locale } = useRouter();

  const selectedLanguage = locale;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsClosing(true);
    document.body.style.overflow = "visible";
    setTimeout(() => {
      setIsModalOpen(false);
      setIsClosing(false);
    }, 300);
  };

  // Set selected lang by setting the NEXT_LOCALE cookie
  const changeLanguage = async (lang) => {
    dispatch(setSelectedLang(lang));
    set_cookie(lang);
    closeModal();
    await Router.push(asPath.toLowerCase(), asPath.toLowerCase(), {
      locale: lang,
    });
    setTimeout(() => {
      Router.reload(window.location.pathname);
    }, 300);
  };

  // Add an event listener to check if user clicked on modal backdrop to close the modal
  useEffect(() => {
    // Handles the click event on the window and closes the modal if the target is the language-selector-modal.
    const handleWindowClick = (event) => {
      if (event.target.classList.contains(styles["language-selector-modal"])) {
        closeModal();
      }
    };

    // Add the event listener to the window object
    window.addEventListener("click", handleWindowClick);

    // Remove the event listener when component unmounted
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  const LanguageListItem = ({ localeItem }) => {
    const { language, flag: FlagComponent } = LocalesMapping[localeItem];
    return (
      <li
        className={`${styles["language-selector-locale-item"]}`}
        onClick={() => changeLanguage(localeItem)}
      >
        <FlagComponent />
        <span className={`${styles["language-selector-locale-name"]}`}>{localeItem}</span>
        <span className={`${styles["language-selector-locale-divider"]}`}></span>
        <span className={`${styles["language-selector-locale-language"]}`}>{language}</span>
      </li>
    );
  };

  return (
    <>
      <button
        className={`${styles["locale-button"]}`}
        data-is-horizontal={isHorizontal}
        onClick={openModal}
      >
        <GlobeIcon className={`${styles["language-selector-globe-icon"]}`} width="18" height="19" />
        <span className={`${styles["locale-button-txt"]}`}>{selectedLanguage}</span>
      </button>
      <div
        className={`${styles["language-selector-modal"]} ${
          isModalOpen ? styles["language-selector-modal-open"] : ""
        } ${isClosing ? styles["language-selector-modal-closing"] : ""}`}
        onAnimationEnd={() => {
          if (!isModalOpen) {
            closeModal();
          }
        }}
      >
        <div
          className={`${styles["language-selector-modal-content"]} ${
            isClosing ? styles["language-selector-modal-closing"] : ""
          }`}
        >
          <div className={styles["language-selector-modal-header"]}>
            <GlobeIcon
              className={`${styles["language-selector-inner-globe-icon"]}`}
              width="18"
              height="19"
              style={{ marginRight: "8px" }}
            />
            <span onClick={closeModal}>
              <CloseIcon color="#666666" style={{ cursor: "pointer" }} />
            </span>
          </div>
          <h6 className={`${styles["language-selector-modal-title"]}`}>Select your language</h6>
          <ul className={styles["language-selector-modal-list"]}>
            {locales?.length > 1 &&
              locales.map((localeItem, index) => {
                return <LanguageListItem key={index} localeItem={localeItem} />;
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LangSelector;
