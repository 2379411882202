import ItalianoFlagIcon from "@/components/Icons/LanguageFlagIcons/ItalianoFlagIcon";
import EnglishFlagIcon from "@/components/Icons/LanguageFlagIcons/EnglishFlagIcon";
import FrancaisFlagIcon from "@/components/Icons/LanguageFlagIcons/FrancaisFlagIcon";
import DeutschFlagIcon from "@/components/Icons/LanguageFlagIcons/DeutschFlagIcon";
import DutchFlagIcon from "@/components/Icons/LanguageFlagIcons/DutchFlagIcon";

export const LocalesMapping = {
  it: {
    language: "Italiano",
    flag: ItalianoFlagIcon,
  },
  en: {
    language: "English",
    flag: EnglishFlagIcon,
  },
  fr: {
    language: "Français",
    flag: FrancaisFlagIcon,
  },
  de: {
    language: "Deutsch",
    flag: DeutschFlagIcon,
  },
  nl: {
    language: "Dutch",
    flag: DutchFlagIcon,
  },
};
