import React from "react";

const CloseIcon = ({ color = "#333333", width = "10", height = "10", style }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.64645 4.35355L0 0.707107L0.707107 0L4.35355 3.64645L8 0L8.70711 0.707107L5.06066 4.35355L8.70711 8L8 8.70711L4.35355 5.06066L0.707107 8.70711L0 8L3.64645 4.35355Z"
    />
  </svg>
);

export default CloseIcon;
